/* eslint-disable no-param-reassign */
import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
// eslint-disable-next-line no-unused-vars

export const API_URL = process.env.VUE_APP_API_KEY;
// export const API_URL = 'http://test.nuwa.com/api/';
// let AUTH_TOKEN = null;

Vue.prototype.$axios = axios;

export function toFormData(data, qstr = false) {
  // console.log('course apply', data);
  if (qstr) return qs.stringify(data);

  const form = new FormData();
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === 'object' && data[key] !== null) {
      Object.keys(data[key]).forEach((childKey) => {
        form.append(`${key}[${childKey}]`, data[key][childKey]);
      });
    } else {
      form.append(key, data[key]);
    }
  });
  return form;
}

// export function setAxiosHeaders(token = null) {
//   AUTH_TOKEN = token;
// }

export function request(
  {
    baseURL = null,
    url = '',
    method = 'get',
    data = false,
    token = null,
    headers = {},
    params = {},
    binary = false,
  } = {},
) {
  if (token !== null) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  let req = {
    baseURL: baseURL || API_URL,
    url,
    method,
    headers,
    Accept: 'application/json, text/plain, */*',
    mode: 'no-cors',
    params,
  };

  if (['put', 'post', 'patch'].indexOf(method) !== -1 && data !== false) {
    if (binary) {
      headers = {
        ...headers,
        'Content-Type': 'multipart/form-data',
      };
    } else {
      headers = {
        ...headers,
        'Content-Type': 'application/json',
      };
    }
    req = { ...req, data, headers };
  }

  // console.info('req', req);

  return axios(req)
    .then((response) => {
      console.log('response', response);
      return response;
    })
    .catch((err) => {
      console.log('error', err.response);
      return err.response;
    })
    .finally(() => {
      console.log('request end');
    });
}

/**
 * 取得語系uuid
 */
export function getLangUUID(data, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://nuwaapi_dev.howdesign.com.tw/api/',
    url: 'req/getLanguage',
    method: 'get',
    params: { code: data },
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 技術支援
 */
export function createSupport(data, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://nuwaapi_dev.howdesign.com.tw/api/',
    url: 'req/createSupport',
    method: 'post',
    data,
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response.data.message);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    })
    .catch(() => {
      errorFunc('發送失敗');
    });
}

/**
 * 登入
 */
export function logIn(data, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'auth/login',
    method: 'post',
    data,
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    }).catch(() => {
      console.log('xxxxxxxx');
      errorFunc('連線失敗');
    });
}

/**
 * 取得國家代碼
 */
export function getCountryCode() {
  return request({
    // baseURL: '',
    url: 'country',
    method: 'get',
  })
    .then((response) => response.data)
    .catch();
}

/**
 * 註冊
 */
export function signUp(data, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'auth/register',
    method: 'post',
    data,
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response.data);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    }).catch((err) => {
      console.log('xxxxx', err);
      errorFunc('連線失敗');
    });
}

/**
 * 修改密碼
 */
export function changePassword(token, data, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'member/changePassword',
    method: 'post',
    token,
    data,
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response.data.message);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    }).catch(() => {
      errorFunc('連線失敗');
    });
}

/**
 * 取得註冊驗證碼
 */
// eslint-disable-next-line no-unused-vars
export function getVerifyCode(data, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'auth/getVerifyCode',
    method: 'post',
    data,
  })
    .then((response) => response)
    .catch((error) => error);
  // .then((response) => {
  //   if (
  //     response.status >= 200
  //     && response.status < 300
  //     && response.data.status
  //     && sussesFunc
  //   ) {
  //     sussesFunc(response.data.message);
  //   } else if (
  //     response.status >= 400
  //     && !response.data.status
  //     && errorFunc
  //   ) {
  //     errorFunc(response.data.message);
  //   }
  // })
  // .catch(() => {
  //   errorFunc('取得註冊驗證碼失敗');
  // });
}

/**
 * 忘記密碼
 */
export function resetPassword(data, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'auth/forget',
    method: 'post',
    data,
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc();
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    }).catch(() => {
      errorFunc('連線失敗');
    });
}

/**
 * 取得診別所屬醫生
 */
export function getDoctor(data) {
  return request({
    url: `clinic/getDoctors/${data}`,
    method: 'get',
  })
    .then((response) => response.data)
    .catch();
}

/**
 * 取得醫生班表
 */
export function getSchedule(data, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'doctor/getSchedule',
    method: 'post',
    data,
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response.data);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    })
    .catch();
}

/**
 * 網路掛號前檢驗會員資料
 */
export function checkMemberData(data, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'auth/checkMemberData',
    method: 'post',
    data,
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    }).catch(() => {
      errorFunc('連線失敗');
    });
}

/**
 * 網路掛號
 */
export function appointment(token, data, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'member/appointment',
    method: 'post',
    token,
    data,
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status === 401
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(401);
      } else if (
        response.status === 402
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(402);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    }).catch(() => {
      errorFunc('連線失敗');
    });
}

/**
 * 取消掛號
 */
export function cancelAppointment(token, data, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'member/cancelAppointment',
    method: 'post',
    data,
    token,
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    }).catch(() => {
      errorFunc('連線失敗');
    });
}

/**
 * 取得圖形驗證碼
 */
export function getCaptchaImg() {
  return request({
    url: 'captcha/getCaptchaImg',
    method: 'get',
  })
    .then((response) => response.data)
    .catch();
}

/**
 * 驗證圖形驗證嗎
 */
export function captchaVerifyCode(data, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://192.168.1.214/api/',
    url: 'captcha/verifyCode',
    method: 'post',
    data,
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status === 401
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(401);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(400);
      }
    }).catch(() => {
      errorFunc('連線失敗');
    });
}

/**
 * 取得會員網路掛號
 */
export function checkAppointment(token, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'member/getAppointmentLog',
    method: 'get',
    token,
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && response.status !== 404
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    }).catch(() => {
      errorFunc('連線失敗');
    });
}

/**
 * 取得會員基本資料
 */
export function getMemberProfile(token, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'member/profile',
    method: 'get',
    token,
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    }).catch(() => {
      errorFunc('連線失敗');
    });
}

/**
 * 回傳中文星期
 */
export function returnZhDay(d) {
  // console.log('SINGLEDATE', new Date('2021-2-5'.replace(/-/g, '/')));
  let temp = '';
  temp = d.getUTCDay() + 1;
  let zhDay = '';
  switch (temp) {
    case 1:
      zhDay = '一';
      break;
    case 2:
      zhDay = '二';
      break;
    case 3:
      zhDay = '三';
      break;
    case 4:
      zhDay = '四';
      break;
    case 5:
      zhDay = '五';
      break;
    case 6:
      zhDay = '六';
      break;
    case 7:
      zhDay = '日';
      break;
    default:
      break;
  }
  return zhDay;
}

/**
 * 回傳中文星期-修正
 */
export function returnZhDayUpdate(d) {
  // console.log('SINGLEDATE', new Date('2021-2-5'.replace(/-/g, '/')));
  const temp = d.getUTCDay();
  let zhDay = '';
  switch (temp) {
    case 1:
      zhDay = '一';
      break;
    case 2:
      zhDay = '二';
      break;
    case 3:
      zhDay = '三';
      break;
    case 4:
      zhDay = '四';
      break;
    case 5:
      zhDay = '五';
      break;
    case 6:
      zhDay = '六';
      break;
    case 7:
      zhDay = '日';
      break;
    default:
      break;
  }
  return zhDay;
}

// ===============
// 網頁文章.內容部分
// ===============

/**
 * 取得全部生殖療程
 */
export function getProcreationArticles(data, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://192.168.1.214/api/',
    url: 'req/getProcreationArticles',
    method: 'get',
    params: { language_id: data },
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得指定生殖療程內容
 */
export function getAssignProcreationArticle(slug, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://192.168.1.214/api/',
    url: `req/procreation/${slug}`,
    method: 'get',
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得全部實驗室療程
 */
export function getLabsArticles(data, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://192.168.1.214/api/',
    url: 'req/getLabsArticles',
    method: 'get',
    params: { language_id: data },
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得指定實驗室內容
 */
export function getAssignLabsArticle(slug, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://192.168.1.214/api/',
    url: `req/labs/${slug}`,
    method: 'get',
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得全部中醫療程
 */
export function getTraditionalArticles(data, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://192.168.1.221/api/',
    url: 'req/getTraditionalArticles',
    method: 'get',
    params: { language_id: data },
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得指定中醫療程內容
 */
export function getAssignTraditionalArticle(slug, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://192.168.1.214/api/',
    url: `req/tranditional/${slug}`,
    method: 'get',
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得關於宜韻的位置輪播圖
 */
export function getLocationPic(data = null, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://nuwaapi_test.howdesign.com.tw/api/',
    url: 'req/getLocationPic',
    method: 'get',
    params: { language_id: data },
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得最新消息所有分類
 */
export function getNewsTags(data = null, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://127.0.0.1/api/',
    url: 'req/getNewsTags',
    method: 'get',
    params: { language_id: data },
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得指定最新消息的內容
 */
export function getNews(data, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://127.0.0.1/api/',
    url: 'req/getNews',
    method: 'get',
    params: { slug: data },
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得所有分類的最新消息
 */
export function getNewsAllByTag(data, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://127.0.0.1/api/',
    url: 'req/getNewsAllByTag',
    method: 'get',
    params: data,
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得特定分類下所有的最新消息
 */
export function getNewsByTag(data, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://127.0.0.1/api/',
    url: 'req/getNewsByTag',
    method: 'get',
    params: data,
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得醫療團隊的團隊分類
 */
export function getTeamTypes(data = null, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://127.0.0.1/api/',
    url: 'req/getTeamTypes',
    method: 'get',
    params: { language_id: data },
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得特定分類下所有的團隊成員
 */
export function getTeams(data, lang = null, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://127.0.0.1/api/',
    url: 'req/getTeams',
    method: 'get',
    params: { slug: data, language_id: lang },
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得宜蘊宜孕故事最新九筆資料
 * @param sussesFunc
 * @param errorFunc
 * @returns {*}
 */
export function getStoryList(data = null, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'req/getStories',
    method: 'get',
    params: { language_id: data },
  })
    .then((res) => {
      if (res.data) {
        sussesFunc(res);
      } else {
        errorFunc(res.data.message);
      }
    })
    .catch();
}

/**
 * 取得特定頁數的宜蘊故事資料
 * @param sussesFunc
 * @param errorFunc
 * @returns {*}
 */
export function getPageStories(data, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'req/getPageStories',
    method: 'get',
    params: data,
  })
    .then((res) => {
      if (res.data) {
        sussesFunc(res);
      } else {
        errorFunc(res.data.message);
      }
    })
    .catch();
}

/**
 * 取得故事內容
 * @param slug
 * @param sussesFunc
 * @param errorFunc
 * @returns {*}
 */
export function getStoryContent(slug, sussesFunc = null, errorFunc = null) {
  return request({
    url: `req/getStoryContent?slug=${slug}`,
    method: 'get',
  })
    .then((res) => {
      if (res.data) {
        sussesFunc(res);
      } else {
        errorFunc(res.data.message);
      }
    })
    .catch();
}

/**
 * 取得國際醫療中心的文件資料
 * @param sussesFunc
 * @param errorFunc
 * @returns {*}
 */
export function getDocuments(data = null, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://127.0.0.1/api/',
    url: 'req/getDocuments',
    method: 'get',
    params: { language_id: data },
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得最新的五筆最新消息
 */
export function getNewsByNew(data = null, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://127.0.0.1/api/',
    url: 'req/getNewsByNew',
    method: 'get',
    params: { language_id: data },
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得首頁輪播最新5則
 * @param sussesFunc
 * @param errorFunc
 * @returns {*}
 */
export function getBanners(data, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'req/getBanners',
    method: 'get',
    params: { language_id: data },
  })
    .then((res) => {
      if (res.data) {
        sussesFunc(res);
      } else {
        errorFunc(res.data.message);
      }
    })
    .catch();
}

/**
 * 取得最新總表
 * @param clinic_id
 * @param sussesFunc
 * @param errorFunc
 * @returns {*}
 */
export function getFirstSchedule(data, lang = null, sussesFunc = null, errorFunc = null) {
  return request({
    url: 'req/getFirstSchedule',
    method: 'get',
    params: { clinic_id: data, language_id: lang },
  })
    .then((res) => {
      if (res.data) {
        sussesFunc(res);
      } else {
        errorFunc(res.data.message);
      }
    })
    .catch();
}

/**
 * 取得故事文章預覽
 * @param slug
 * @param sussesFunc
 * @param errorFunc
 * @returns {*}
 */
export function getStoryContentPreview(slug, sussesFunc = null, errorFunc = null) {
  return request({
    url: `req/getStoryContentPreview?slug=${slug}`,
    method: 'get',
  })
    .then((res) => {
      if (res.data) {
        sussesFunc(res);
      } else {
        errorFunc(res.data.message);
      }
    })
    .catch();
}

/**
 * 取得生殖療程預覽內容
 */
export function getAssignProcreationArticlePreview(slug, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://192.168.1.214/api/',
    url: `req/preview/procreation/${slug}`,
    method: 'get',
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得中醫療程預覽內容
 */
export function getAssignTraditionalArticlePreview(slug, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://192.168.1.214/api/',
    url: `req/preview/tranditional/${slug}`,
    method: 'get',
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得實驗室療程預覽內容
 */
export function getAssignLabsArticlePreview(slug, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://192.168.1.214/api/',
    url: `req/preview/labs/${slug}`,
    method: 'get',
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得生殖療程團隊內容
 */
export function getIvfTeamsContent(data = null, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://192.168.1.214/api/',
    url: 'req/ivfTeamsContent',
    method: 'get',
    params: { language_id: data },
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得中醫療程團隊內容
 */
export function getTcmTeamsContent(data = null, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://192.168.1.214/api/',
    url: 'req/tcmTeamsContent',
    method: 'get',
    params: { language_id: data },
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得實驗室團隊內容
 */
export function getLabsTeamsContent(data = null, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: 'http://192.168.1.214/api/',
    url: 'req/labsTeamsContent',
    method: 'get',
    params: { language_id: data },
  })
    .then((response) => {
      if (
        response.status >= 200
        && response.status < 300
        && response.data.status
        && sussesFunc
      ) {
        sussesFunc(response);
      } else if (
        response.status >= 400
        && !response.data.status
        && errorFunc
      ) {
        errorFunc(response.data.message);
      }
    });
}

/**
 * 取得國際醫療中心文案
 */
export function getInternational(data = null, sussesFunc = null, errorFunc = null) {
  return request({
    // baseURL: '',
    url: 'req/getInternational',
    method: 'get',
    params: { language_id: data },
  }).then((response) => {
    if (
      response.status >= 200
      && response.status < 300
      && response.data.status
      && sussesFunc
    ) {
      sussesFunc(response);
    } else if (
      response.status >= 400
      && !response.data.status
      && errorFunc
    ) {
      errorFunc(response.data.message);
    }
  });
}
