<template>
  <section id="kv">
    <swiper :options="swiperOption" class="kv" v-if="kv.length">
      <swiper-slide
      v-for="(item, key) in kv"
      :key="key"
      :style="{backgroundImage: `url(${item.pic})`}"
      >
        <div class="swiper-content">
          <div class="w1200">
            <h4
            class="eng"
            v-html="item.zhSlogan">
            </h4>
            <!-- <br> -->
            <!--
            <h6 class="txt-medium">
              <span v-html="item.enSlogan"></span>
              <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
            </h6>
            -->
            <a :href="item.path" class="btn" v-if="item.path != null">{{$t('lookingMore')}}</a>
            <!-- <router-link :to="item.path" class="btn">了解更多</router-link> -->
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <swiper :options="swiperOption" class="kv_mobile">
      <swiper-slide
      v-for="(item, key) in kv"
      :key="key"
      :style="{backgroundImage: `url(${item.pic_mb})`}"
      >
        <div class="swiper-content">
          <div class="w1200">
            <h4
            class="eng"
            v-html="item.zhSlogan">
            </h4>
            <!-- <br> -->
            <!--
            <h6 class="txt-medium">
              <span v-html="item.enSlogan"></span>
              <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
            </h6>
            -->
            <a :href="item.path" class="btn" :class="{ 'noText': item.zhSlogan === null || item.zhSlogan === '' }" v-if="item.path != null">{{$t('lookingMore')}}</a>
            <!-- <router-link :to="item.path" class="btn">了解更多</router-link> -->
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </section>
</template>

<script>
import { getBanners } from '@/lib/public';
import { mapMutations, mapGetters } from 'vuex';

// import kv1 from '@/assets/img/temp/banner_1.jpg';
// import kv2 from '@/assets/img/temp/banner_2.jpg';
// import kv3 from '@/assets/img/temp/banner_3.jpg';

// import kv1Mobile from '@/assets/img/temp/banner_1_mobile.jpg';
// import kv2Mobile from '@/assets/img/temp/banner_2_mobile.jpg';
// import kv3Mobile from '@/assets/img/temp/banner_3_mobile.jpg';

export default {
  name: 'IndexKv',
  data() {
    return {
      swiperOption: {
        autoplay: true,
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        pagination: {
          el: '#kv .swiper-pagination',
          clickable: true,
        },
      },
      kv: [],
    };
  },
  computed: {
    ...mapGetters([
      'getLangUUID',
    ]),
  },
  methods: {
    ...mapMutations([
      'setLangUUID',
    ]),
    getBanners() {
      getBanners(
        this.getLangUUID,
        (res) => {
          if (res.status === 200) {
            if (res.data.status) {
              res.data.data.forEach((banner) => {
                this.kv.push({
                  path: banner.url,
                  pic: banner.banner_pc,
                  pic_mb: banner.banner_mobile,
                  zhSlogan: banner.title,
                });
              });
            }
          }
        },
      );
    },
  },
  watch: {
    getLangUUID(val) {
      if (val !== null) {
        this.getBanners();
      }
    },
  },
  mounted() {
    if (this.getLangUUID !== null) {
      this.getBanners();
    }
  },
};
</script>
