<template>
  <div id="app">
    <SocialLightbox />
    <Header :indexHeader="indexHeader"/>
    <transition
      enter-active-class="page-fade-enter-active"
      leave-active-class="page-fade-leave-active">
      <router-view/>
    </transition>
    <Footer/>
    <QuickLinks/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import QuickLinks from '@/components/QuickLinks.vue';
import SocialLightbox from '@/components/SocialLightbox.vue';
import VueCookies from 'vue-cookies';
import Axios from 'axios';
import { getLangUUID } from '@/lib/public';
import { mapGetters, mapMutations } from 'vuex';
import '@/assets/scss/main.scss';
import '@/assets/scss/public.scss';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    QuickLinks,
    SocialLightbox,
  },
  data() {
    return {
      indexHeader: true,
      sociallightboxOpen: false,
      socialItem: 1,
    };
  },
  computed: {
    ...mapGetters([
      'getLanguage',
    ]),
  },
  methods: {
    ...mapMutations([
      'initMeb',
      'setLanguage',
      'setLangUUID',
    ]),
    changeHeaderClass() {
      if (this.$route.path === '/') {
        this.indexHeader = true;
      } else {
        this.indexHeader = false;
      }
    },
    clickCloseLightbox() {
      this.sociallightboxOpen = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
    clickOpenLightbox(type) {
      this.sociallightboxOpen = true;
      this.socialItem = type;
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
    },
    getLangUUID(lang) {
      getLangUUID(
        lang,
        (res) => {
          if (res.status === 200) {
            if (res.data.status) {
              this.setLangUUID(res.data.data.id);
            }
          }
        },
      );
    },
    languagesProcess(lang) {
      const langType = new Promise((resolve) => {
        switch (lang) {
          // 繁體
          case 'zh-tw':
            resolve('zh_TW');
            break;
          case 'zh':
            resolve('zh_TW');
            break;
          case 'zh-hk':
            resolve('zh_TW');
            break;
          case 'zh-mo':
            resolve('zh_TW');
            break;
          case 'zh-sg':
            resolve('zh_TW');
            break;
          case 'zh-hant-hk':
            resolve('zh_TW');
            break;
          case 'zh-hant-mo':
            resolve('zh_TW');
            break;
          case 'zh-hant-tw':
            resolve('zh_TW');
            break;
          case 'zh-hant':
            resolve('zh_TW');
            break;
          // 簡體中文
          case 'hans':
            resolve('zh_CN');
            break;
          case 'zh-hans-cn':
            resolve('zh_CN');
            break;
          case 'zh-hans-hk':
            resolve('zh_CN');
            break;
          case 'zh-hans-mo':
            resolve('zh_CN');
            break;
          case 'zh-hans-sg':
            resolve('zh_CN');
            break;
          case 'zh-hans-hans':
            resolve('zh_CN');
            break;
          case 'zh-cn':
            resolve('zh_CN');
            break;
          // 英文
          case 'en-':
            resolve('en-US');
            break;
          default:
            resolve('zh_TW');
        }
      });

      Promise.all([langType]).then((values) => {
        this.setLanguage(values[0]);
        this.getLangUUID(values[0]);
      });
    },
  },
  created() {
    const frontUrl = 'https://www.nuwacare.com/';
    Axios.get('https://api.ipify.org?format=json')
      .then((res) => {
        if (res.status) {
          if (res.data.ip !== '114.34.52.209') {
            window.location.replace(frontUrl);
          }
        }
      }).catch(() => {
        window.location.replace(frontUrl);
      });
    this.languagesProcess(VueCookies.get('lang'));
    this.initMeb();
  },
  mounted() {
    this.changeHeaderClass();
  },
  updated() {
    this.changeHeaderClass();
  },
};
</script>
