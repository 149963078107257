<template>
  <div class="socialLightbox" v-show="socialLightbox.status">
    <div class="cover" @click="clickCloseLightbox()"></div>
    <div class="box">
      <div class="fake_close_btn close" @click="clickCloseLightbox()"></div>
        <img v-show="socialLightbox.type === 0" src="@/assets/img/LINE@.jpg" />
        <img v-show="socialLightbox.type === 1" src="@/assets/img/WhatsApp.jpg" />
        <img v-show="socialLightbox.type === 2" src="@/assets/img/weChat.jpg" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'SocialLightbox',
  data() {
    return {
    };
  },
  computed: {
    ...mapState([
      'socialLightbox',
      'bodyLock',
    ]),
  },
  watch: {
    bodyLock(v) {
      const body = document.getElementsByTagName('body')[0];
      if (v) {
        body.classList.add('lock');
      } else {
        body.classList.remove('lock');
      }
    },
  },
  methods: {
    ...mapMutations([
      'setSocialLightbox',
      'setBodylock',
    ]),
    clickCloseLightbox() {
      this.setSocialLightbox({ status: false });
      this.setBodylock(false);
    },
  },
};
</script>
