<template>
  <div>
    <div class="header_area" :style="{height: headerAreaHeight}"></div>
    <header id="header" :class="{normal: !indexHeader, isfixed: isFixed}" ref="header">
      <div class="container">
        <div class="logo">
          <router-link to="/">
            <img
            v-if="getLanguage === 'en-US'"
            class="color En" src="@/assets/img/logo-en.svg" alt="logo"/>
            <img
            v-else-if="getLanguage === 'zh_CN'"
            class="color Cn" src="@/assets/img/logo-cn.svg" alt="logo"/>
            <img
            v-else
            class="color" src="@/assets/img/logo.svg" alt="logo"/>
            <img
            v-if="getLanguage === 'en-US'"
            class="w" src="@/assets/img/logo-w-en.svg" alt="logo"/>
            <img
            v-else-if="getLanguage === 'zh_CN'"
            class="w" src="@/assets/img/logo-w-cn.svg" alt="logo"/>
            <img
            v-else
            class="w" src="@/assets/img/logo-w.svg" alt="logo"/>
          </router-link>
        </div>
        <div class="navbar" :class="{active : isHamburgerActive}">
          <img src="@/assets/img/x.svg" alt="" class="hamberger_x"
            @click="isHamburgerActive = !isHamburgerActive;">
          <ul class="navbar-list" style="line-height:2.4em">
            <li
            :class="[getLanguage === 'en-US' ? 'liEn' : '']"
            @click="linkClose('/about/ivf')">{{ $t('nuwaIvfHeader') }}</li>
            <li
            :class="[getLanguage === 'en-US' ? 'liEn' : '']"
            @click="linkClose('/about/tcm')">{{ $t('nuwaTcmHeader') }}</li>
            <li
            :class="[getLanguage === 'en-US' ? 'liEn' : '']"
            @click="linkClose('/about/lab')">{{ $t('nuwaLabsHeader') }}</li>
            <li
            :class="[getLanguage === 'en-US' ? 'liEn' : '']"
            @click="linkClose('/about/nuwa')">{{ $t('aboutNuwaHeader') }}</li>
            <li
            :class="[getLanguage === 'en-US' ? 'liEn' : '']"
            @click="linkClose('/staff/procreation/p1')">{{ $t('nuwaTeamsHeader') }}</li>
            <li
            :class="[getLanguage === 'en-US' ? 'liEn' : '']"
            @click="linkClose('/story/p1')">{{ $t('nuwaStoriesHeader') }}</li>
            <li
            :class="[getLanguage === 'en-US' ? 'liEn' : '']"
            @click="linkClose('/international')">{{ $t('internationalMedicalCenterHeader') }}</li>
            <li
            :class="[getLanguage === 'en-US' ? 'liEn' : '']"
            @click="linkClose('/news/all/1')">{{ $t('newsHeader') }}</li>
          </ul>
          <ul class="navbar-btns" style="line-height:2.4em">
            <li
            :class="[getLanguage === 'en-US' ? 'liEn' : '']"
            @click="linkClose('/registration')">{{ $t('booking') }}</li>
            <li
            :class="[getLanguage === 'en-US' ? 'liEn' : '']"
            @click="linkClose('/login')" v-if="!getMemberAll.api_token">{{ $t('login') }}</li>
            <li
            :class="[getLanguage === 'en-US' ? 'liEn' : '']"
            @click="linkClose('/memberhome')" v-else>
              <p>
                {{getMemberAll.name}},
                <span style="cursor: pointer;" @click="clickLogOut();">{{ $t('logout') }}</span>
              </p>
            </li>
          </ul>
          <!-- <a class="navbar-lang">中文</a> -->
        </div>
        <div class="hamburger">
          <div id="hamburger" @click="isHamburgerActive = !isHamburgerActive;">
            <div></div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Header',
  props: ['indexHeader'],
  data() {
    return {
      scrollDistence: window.scrollY,
      screenHeight: window.innerHeight,
      headerAreaHeight: '',
      isFixed: false,
      isHamburgerActive: false,
    };
  },
  methods: {
    ...mapMutations([
      'logOut',
    ]),
    listenScrollDistence() {
      this.scrollDistence = window.scrollY;
    },
    clickLogOut() {
      this.logOut();
      this.$router.push('/');
    },
    linkClose(link) {
      if (this.$route.path !== link) {
        this.isHamburgerActive = false;
        this.$router.push(link);
      }
    },
  },
  computed: {
    ...mapGetters([
      'getMemberAll',
      'getLanguage',
    ]),
  },
  watch: {
    scrollDistence() {
      if (this.scrollDistence >= this.screenHeight) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.listenScrollDistence, true);
    console.log(this.getMemberAll);
  },
  mounted() {
    this.headerAreaHeight = `${this.$refs.header.clientHeight}px`;
  },
  updated() {
    this.headerAreaHeight = `${this.$refs.header.clientHeight}px`;
  },
  destroyed() {
    window.removeEventListener('scroll', this.listenScrollDistence, true);
  },
};
</script>
