import enUS from './en-us';
import zhTW from './zh-tw';
import zhHans from './zh-hans';

export default {
  'zh-tw': zhTW,
  // eslint-disable-next-line
  'zh': zhTW,
  'zh-hk': zhTW,
  'zh-mo': zhTW,
  'zh-sg': zhTW,
  'zh-hant-hk': zhTW,
  'zh-hant-mo': zhTW,
  'zh-hant-tw': zhTW,
  'zh-hant': zhTW,
  'zh-hans-cn': zhHans,
  'zh-hans-hk': zhHans,
  'zh-hans-mo': zhHans,
  'zh-hans-sg': zhHans,
  'zh-hans-hans': zhHans,
  'zh-cn': zhHans,
  'en-': enUS,
};
