import Vue from 'vue';

// Swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
// axios
import axios from 'axios';
import VueAxios from 'vue-axios';
// sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueCookies from 'vue-cookies';
// media query
import VueMq from 'vue-mq';
// font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileDownload, fas } from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Paginate from 'vuejs-paginate';

// i18n
import VueI18n from 'vue-i18n';
import messages from './i18n';
import getLang from './i18n/getUserLangueages/getLang';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(VueAwesomeSwiper);
Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);
Vue.use(VueCookies);
Vue.use(VueMq, {
  breakpoints: {
    mobile: 500,
  },
});

library.add(faFileDownload, faPlusSquare, fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('paginate', Paginate);

let lang = VueCookies.get('lang');

if (!VueCookies.get('lang')) {
  let userLang = navigator.language || navigator.userLanguage;

  console.log(userLang.substring(0, 3));

  if (userLang.toLocaleLowerCase().substring(0, 2) === 'en') {
    userLang = 'en-';
  }

  const langArr = [];
  Object.keys(messages).forEach((key) => {
    langArr.push(key);
  });

  lang = getLang.getLang(langArr, userLang, 'zh-tw');

  VueCookies.set('lang', lang);
}

const i18n = new VueI18n({
  locale: lang,
  messages,
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
  created() {
    // eslint-disable-next-line no-proto
    this.__proto__.$customSWAL = (
      {
        icon = 'success',
        title = '',
        text = null,
        thenFunc = null,
        confirmButtonText = this.$t('確定'),
      } = {},
    ) => {
      this.$swal.fire({
        position: 'center-center',
        icon,
        title: title || this.$t('message'),
        text,
        confirmButtonText,
        showClass: { popup: 'animated fadeInDown faster' },
        hideClass: { popup: 'animated fadeOutUp faster' },
      }).then(() => {
        if (thenFunc) {
          thenFunc();
        }
      });
    };
  },
}).$mount('#app');
