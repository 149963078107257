<template>
  <div class="home">
    <IndexKv/>
    <IndexAbout/>
    <IndexBaby/>
    <IndexDoctors/>
    <IndexNews/>
    <IndexImgLink/>
  </div>
</template>

<script>
// @ is an alias to /src
import '@/assets/scss/index.scss';
import IndexKv from '@/components/index/IndexKv.vue';
import IndexAbout from '@/components/index/IndexAbout.vue';
import IndexBaby from '@/components/index/IndexBaby.vue';
import IndexDoctors from '@/components/index/IndexDoctors.vue';
import IndexNews from '@/components/index/IndexNews.vue';
import IndexImgLink from '@/components/index/IndexImgLink.vue';

export default {
  name: 'Home',
  components: {
    IndexKv,
    IndexAbout,
    IndexBaby,
    IndexDoctors,
    IndexNews,
    IndexImgLink,
  },
};
</script>
