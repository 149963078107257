export default {

  // 大分類//
  nuwaIvf: '宜蘊生殖醫學中心',
  nuwaTcm: '宜蘊中醫診所',
  nuwaLabs: '宜蘊胚胎實驗室',
  nuwaTeams: '全女性醫療團隊',
  nuwaStories: '宜蘊宜孕故事',
  news: '衛教與最新消息',
  aboutNuwaPublic: '關於宜蘊醫療',
  visa: '簽證申請暨來台流程',
  introTcm: '中醫療程介紹',
  aboutNuwa: '關於宜蘊',
  tcmTitle: '宜蘊中醫',

  // 標語 //
  DoctorsSlogan: '交給宜蘊 讓妳懷孕',
  tcmContent: '逾二十年經驗豐富的女中醫師團隊，專精不孕症、養卵、增厚內膜、胚胎植入前後、小產、孕期、經前不適、經痛、過敏體質改善、產後、減重、睡眠障礙等中藥調理，並設有獨立針灸室，保有治療期間高度隱密性，提供更為舒適的中醫就診環境，幫助女性朋友在求孕、身體調養這條路上，能走得更為舒適、順遂！',
  ivfContent: 'HUMANITY、TECHNOLOGY、CARE是宜蘊生殖醫學中心的三大核心，以女性為本的整合式健康照護，全女性醫療團隊，給予醫療科技支持及女性心理層面的全方位照護，重視傾聽每位來診者的聲音，透過女性同理心提供醫師專業協助，讓心的距離更近，診治過程更有溫度，宜蘊為您打造適宜懷孕的方案。',

  // button //
  readAritcal: '閱讀完整文章',
  more: '查看更多',
  introTeams: '團隊介紹',
  lookingTeams: '查看團隊',
  lookingDoctors: '查看醫師',
  lookingMore: '了解更多',
  quicklink: '即時諮詢',
  moreNuwa: '更多宜蘊',
  bookingNow: '立即掛號',
  reservation: '立即預約',
  share: '分享',
  sure: '確定',
  message: '訊息',
  copyComplete: '複製完成',
  back: '返回公告',
  backToStory: '返回宜孕故事',
  aboutTreatment: '療程介紹',
  ivfTreatment: '生殖療程',
  tcmTreatment: '中醫療程',
  contact: '專人服務',

  // 地址 //
  ivfAddress: '台北市大安區信義路三段149號15樓',
  tcmAddress: '台北市大安區信義路三段149號14樓之一',

  // title //
  oneForOneService: '一對一專人服務',

  // time //
  am: '上午',
  noon: '中午',
  pm: '下午',
  night: '晚上',
  betweenNight: '晚間',
  monday: '週一',
  thursday: '週四',
  friday: '週五',
  saturday: '週六',

  // ------header------//

  nuwaIvfHeader: '宜蘊生殖醫學中心',
  nuwaTcmHeader: '宜蘊中醫診所',
  nuwaLabsHeader: '宜蘊胚胎實驗室',
  nuwaTeamsHeader: '全女性醫療團隊',
  nuwaStoriesHeader: '宜蘊宜孕故事',
  newsHeader: '衛教與最新消息',
  aboutNuwaPublicHeader: '關於宜蘊醫療',
  aboutNuwaHeader: '關於宜蘊',
  internationalMedicalCenterHeader: '國際醫療中心',

  language: '語言',
  internationalMedicalCenter: '國際醫療中心',
  booking: '預約掛號',
  login: '登入',
  logout: '登出',

  // ------footer------//
  address: '地址',
  contactText: '預約諮詢',
  // privact and reserved
  privacy: '隱私權條款',
  reservedText: '本網站所有內容、資訊及相關權利均為宜蘊婦產科診以及宜蘊中醫診所所有或被授權，任何人未經事前書面同意，不得為任何重製、轉載、散布、改作、公開傳輸、公開發表等侵害智慧財產權或其他權利之行為，並不得將網站全部或部分內容轉載於任何形式媒體或社群。本網站委由宜蘊生醫股份有限公司維護管理。 ©2021 NUWA FERTILITY CENTER ALL RIGHTS RESERVED.',
  privacyMainTitle: '非常歡迎您光臨本網站，為了讓您能夠安心使用本網站的各項服務與資訊，特此向您說明本網站的隱私權保護政策，以保障您的權益，請您詳閱下列內容：',
  privacyTitle1: '一、隱私權保護政策的適用範圍',
  parivacyContent1: '隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。',
  privacyTitle2: '二、個人資料的蒐集、處理及利用方式',
  privacyContent2: '當您造訪本網站或使用本網站所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。 本網站在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。 於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。 為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。',
  privacyTitle3: '三、資料之保護',
  privacyContent3: '本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。 如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。',
  privacyTitle4: '四、網站對外的相關連結',
  privacyContent4: '本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。',
  privacyTitle5: '五、與第三人共用個人資料之政策',
  privacyContent5: '本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。 前項但書之情形包括不限於： 經由您書面同意。 法律明文規定。 為免除您生命、身體、自由或財產上之危險。 與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集著依其揭露方式無從識別特定之當事人。 當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。 有利於您的權益。 本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。',
  privacyTitle6: '六、Cookie之使用',
  privacyContent6: '為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導至網站某些功能無法正常執行 。',
  privacyTitle7: '七、隱私權保護政策之修正',
  privacyContent8: '本網站隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。',
  // privact and reserved end

  browser: '最佳瀏覽體驗建議瀏覽器: Edge、Firefox、Chrome、Safari ( 螢幕設定最佳顯示效果為1920*1080 )',

  // ------index------//

  // 療程
  introIvf: '生殖醫學療程介紹',
  introlabs: '胚胎實驗室介紹',
  aboutIntroText: '全女性生殖醫療團隊，以女性為本的整合式健康照護，並加入FemTech的新創概念，導入最新生殖細胞儲存技術，搭配實證醫學營養品療程，提升試管嬰兒成功率，提供大中華地區最好的試管嬰兒服務，不論是醫療科技的支持或心理層面的照護。 宜蘊為您打造適宜懷孕的方案。',
  labsContent: '優質的胚胎實驗室及專業胚胎師是試管嬰兒成功與否的關鍵之一，從精卵結合到胚胎養成每個關鍵都仰賴細膩分工的實驗室團隊為您守護，宜蘊胚胎實驗室團隊幫助您解決胚胎問題，迎向好孕。',
  // 故事
  storiesConetnt: '姊妹求孕的暖心故事，一路走來的艱辛終將化成喜悅的淚水及懷中帶有奶香味的寶寶，宜蘊為您打造適宜懷孕的方案，充滿愛包圍的專業中心，開啟您的幸孕之門。',
  // imgLink區塊

  // ------AboutIvf & Tcm & Labs------//
  ivfTitle: '生殖療程介紹',
  aboutIvfIntro: 'HUMANITY、TECHNOLOGY、CARE是宜蘊生殖醫學中心的三大核心，以女性為本的整合式健康照護，全女性醫療團隊，給予醫療科技支持及女性心理層面的全方位照護，宜蘊為您打造適宜懷孕的方案。',
  ivfTeamsTitle: '生殖團隊介紹',
  ivfTeamsIntro: '宜蘊生殖醫學中心，全女醫團隊，專注生殖醫學，重視傾聽每位來診者的聲音，透過女性同理心提供醫師專業協助，讓心的距離更近，診治過程更有溫度。由前臺北醫學大學附設醫院生殖醫學中心副主任及雙和醫院生殖醫學中心主任領軍，全盤照護求診者的生殖之路與身心靈需求。',
  aboutTcmIntro: '逾二十年經驗豐富的女中醫師團隊，專精不孕症調理、養卵、胚胎植入前後調理、小產調理、孕期調理、經期/經痛調理、過敏體質調理、產後調理、睡眠障礙等，設有獨立針灸室，保有治療期間高度隱密性，提供更為舒適的中醫就診環境，幫助女性朋友在求孕、身體調養這條路上，能走得更為順遂!',
  aboutLabsIntro: '國際水準專業度團隊，頂尖胚胎實驗室，除配有頂級胚胎縮時攝影培養箱(Timelapse)，大幅提升植入胚胎之受孕率，每顆胚胎皆由逾博士級、碩士級胚胎師細心照料，獨步全球「胚胎管家制度」每位媽媽配有一位專屬胚胎師一條龍全程照顧，用心溫暖照料每一顆珍貴的胚胎。',
  tcmTeamsTitle: '中醫團隊介紹',
  tcmTeamsIntro: '中醫調理助您擁有好孕體質，宜蘊中醫診所院長為前北醫中醫婦科主任陳玉娟，親身見證生殖技術這二十年進步的觀察者，每月門診求診人次破千人，診治案例達萬例。',
  labsTitle: '關於胚胎實驗室',
  labsIntro: '人才是企業重要資產；資深胚胎師也是宜蘊重要資產。宜蘊胚胎實驗室由一群認同宜蘊理念及經驗豐富的碩博士級胚胎師組成。試管嬰兒療程極度仰賴團隊合作的細膩分工，尤其是試管嬰兒實驗室，必須結合高等生命科學技術及高品質的環境工程。宜蘊胚胎實驗室以專業技術為本，具有三大優勢：頂尖胚胎師、智能培養以及安心環境。提供每一位宜蘊媽媽最專業的技術及最暖心的照護。',
  labsTeamsTitle: '實驗室團隊介紹',
  labsTeamsIntro: '以女性為核心的整合式健康照護，優越的國際醫療能力，FemTech 的新 創概念，導入最新的生殖細胞儲存技術與 Evidence Based 醫學營養品療程，提升 IVF 成功率，提供大中華地區最好的試管嬰兒服務。',

  // ------About Nuwa------//
  humanity: '人性化照護',
  technology: '醫療科技加持',
  care: '以女性為本關懷為優先',
  nuwaTeamsContent: '以女性為核心的生殖醫療照護，由專業女醫師及護理諮詢團隊為您提供服務，婦科私密檢查隱私度提升，並導入最新的生殖細胞儲存技術與實證醫學營養品療程，提升試管嬰兒(IVF)成功率，具有優越的國際醫療能力，提供全大中華地區最好的試管嬰兒服務。',
  aboutLabs: '宜蘊胚胎實驗室由一群認同宜蘊理念及經驗豐富的碩博士級胚胎師組成。試管嬰兒療程極度仰賴團隊合作的細膩分工，尤其是試管嬰兒實驗室，必須結合高等生命科學技術及高品質的環境工程。宜蘊胚胎實驗室以專業技術為本，具有三大優勢：頂尖胚胎師、智能培養以及安心環境。提供每一位宜蘊媽媽最專業的技術及最暖心的照護。',
  advantage: '我們的優勢',
  advantageContent: '打造亞洲第一座全女性醫療人員的生殖醫學中心，在生殖療程路上，提供來診者有溫度的問診，舒適且具隱私的環境， 並提供中西醫診治及諮詢師的心理諮商服務。',
  womenCare: '全女性醫療',
  tcmCare: '中醫助孕',
  customizeCare: '量身打造懷孕方案',
  psyCare: '心理諮商',
  technologyCare: '科技照護平台',
  vipCare: '設VIP門診',
  labsCare: '頂尖胚胎實驗室',
  femTech: 'FemTech概念',
  location: '就診地點',
  ivfLocation: '捷運大安站一號出口，台北市農會信義大樓，搭乘電梯至15樓',
  openTime: '營業時間',
  tcmLocation: '捷運大安站一號出口，台北市農會信義大樓，搭乘電梯至14樓',
  parking: '停車資訊',
  parkingLocation: '附中公園地下停車場 台北市大安區復興南路一段340巷11號',
  parkingLocation_2: 'iRent台北農會中正場 台北市大安區復興南路一段340巷',
  parkingLocation_3: '大安高工地下停車場（信義路3段166巷6弄12號地下）共有526個車位，含身心障礙停車位11格)，位於診所正對面巷子。',
  endText: '等待那一刻的到來',
  // ------International------//
  visaProcess: '簽證辦理',
  professional: '專業可靠',
  oneForOne: '一對一',
  personService: '專人服務',
  medicalService: '醫療服務',
  englishService: '全英文服務',
  contactUs: '聯繫我們',
  readyDocuments: '準備資料',
  provideDocuments: '提供資料',
  contactUsAndComing: '聯繫我們，預約寶寶的到來',
  oneForOneServiceContent: '專門辦理「國際特約醫療」與「自費醫療」提供快速便捷，無語言隔閡的醫療服務。包含，特約門診、旅遊諮詢、來台生活資訊提供等。',
  thisIsNotInsurance: '本中心不屬於台灣全民健保給付範圍',
  dealVisaOrEntry: '辦理醫療簽證或辦理入台證',
  dealVisaOrEntryContent: '宜蘊醫療團隊確認您的狀況後，會有專人與您聯繫確認入境簽證所需的協助事項、預約安排的時間、確認您入境當天如何抵達住宿地點、看診當天如何抵達宜蘊。',
  dealVisaAndSteps: '簽證申請暨來台流程',
  stepOneMidTitle: '夢想總是開始在第一步',
  stepOneInfo: '在您到台灣開始生殖醫學之旅前，請與我們的國際醫療中心諮詢團隊聯繫，我們會協助評估您的狀況及安排療程。 我們會有專人協助您辦理醫療簽證或者辦理入台證。',
  stepTwoeMidTitle: '小文件 大作用',
  stepTwoInfoParam1: '我們需會要請您提供一些文件，來評估及安排您的療程。',
  stepTwoInfoParam2: '夫妻雙方的護照。(大陸地區居民請提供往來臺灣通行證)',
  stepTwoInfoParam3: '中文或英文的結婚證明*2',
  stepTwoInfoParam4: '過往相關病歷資料或影像報告',
  stepThreeeMidTitle: '送出資料 預約夢想的開始',
  stepThreeInfoParam1: '將準備好的文件提供給我們，',
  stepThreeInfoParam2: '我們會在1-3個工作天內與您聯繫。',
  stepThreeInfoParam3: '大陸地區居民入境台灣是使用入臺證。',
  stepThreeInfoParam4: '根據臺灣的《台灣人工繁殖法》，您會需要準備結婚證明，才能在臺灣進行生殖醫學療程。',
  // ------News------//
  all: '全部',
  notFound: '查無文章',
  // ------Registration------//
  onlineBooking: '線上掛號',
  selectList: '請選擇看診項目',
  specialOutpatient: '特約門診',
  OutpatientInfo: '利用本院「線上掛號系統」進行預約，以減少您在現場等候的時間',
  amOutpatient: '上午門診',
  noonOutpatient: '下午門診',
  nightOutpatient: '晚間門診',
  bookingInfo: '網路掛號預約時間為30天(含當日門診)，每日00:00更新 ※各診若人數多需延診時，上述各項結束時間配合延長',
  bookingInfoPrecaution: '掛號注意事項',
  bookingInfoPrecautionContent1: '報到時間：上午門診11:00前報到、下午門診16:00前報到、夜間門診20:00前報到',
  bookingInfoPrecautionContent2: '本院各項費用若有調整將不另行通知，以現場費用為準。宜蘊中醫診所為全自費門診。',
  bookingInfoPrecautionContent3: '若您並非本國國民(具有護照、身分證或健保卡)，將依本院國際醫療標準收費；藥費、檢驗、檢查及其他醫療處置費用等另按本院國際醫療服務價格收費。',
  bookingInfoPrecautionContent4: '如果您希望對本院相關費用或本院國際醫療相關費用有更多了解，歡迎與我們連絡。',
  bookingInfoPrecautionContent5_1: '當天門診網路掛號截止時間:',
  bookingInfoPrecautionContent5_2: '上午門診 10:00',
  bookingInfoPrecautionContent5_3: '下午門診 15:30',
  bookingInfoPrecautionContent5_4: '夜間門診 19:00',
  bookingInfoPrecautionContent5_5: '已預約之當天門診不接受網路退掛，請來電退掛。',
  bookingInfoPrecautionContent6_1: '網路掛號時間 ：全年24小時服務，請多善加利用。',
  bookingInfoPrecautionContent6_2: '門診時間若有異動，請以當月門診時間表為準，謝謝！',
  clinicCallNumberRule: '診間叫號原則',
  clinicCallNumberContent1: '看診依號碼順序叫號，但必須完成報到手續，才會叫看診號。',
  clinicCallNumberContent2: '過號叫診以報到順序為依據，到診時若號碼已過號，請至櫃檯報到由櫃檯通知診間安排順序。',
  clinicCallNumberContent3: '過號叫號原則如下：每2位安排1位過號病人。',
  clinicTcmNotify: '宜蘊中醫貼心提醒',
  clinicTcmNotifyContent_1: '為了維護良好的就醫品質，可於每次就診前事先備好他院檢驗報告或數據、基礎體溫數據或療程進行單，進診間時交給醫師方便評估。',
  clinicTcmNotifyContent_2: '為了節省您寶貴的看診時間，請事先查詢好您下一次預約回診時間或可預約回診時間。',
  clinicTcmNotifyContent_3: '宜蘊中醫視病如親，每一位貴賓的看診時間不一定，需要您的耐心等候，如因故無法等候可請櫃檯人員協助改約時間。',
  clinicTcmNotifyContent_4: '因covid 19疫情，看診須全程配戴口罩，中醫門診就診前請先在家拍好全臉及舌頭照片以協助醫師診斷',
  nuwaSpecialOutpatient: '宜蘊特約門診',
  nuwaSpecialOutpatientInfo_1: '宜蘊特約門診為了追求卓越的生殖醫學服務，為了讓您能輕鬆且迅速地安排您的生殖醫學療程',
  nuwaSpecialOutpatientInfo_2: '宜蘊團隊針對您個人需求，規劃設計專屬於您的VIP服務計劃。',
  clinicServiceTitle: '看診服務內容',
  clinicServiceContent: '特約門診提供您尊榮的門診服務，由專業親切的宜蘊團隊為您安排您的生殖醫學療程，只要一通電話，就能在舒適、寧靜的醫療空間中，擁有宜蘊堅強的生殖醫學團隊所提供的服務，讓您享受個人專屬精緻的生殖醫學服務。',
  phoneBooking: '專線約診',
  phoneBookingContent: '按下宜蘊特約門診預約電話，告知您的時間與需求，即有專人為您預約，並安排適當主治醫師。',
  personReception: '專人接待',
  personReceptionContent: '只要您告知預定到達的時間，我們將提供專人協助就醫流程。',
  vipSpace: '專屬空間',
  vipSpaceContent: '設有專屬舒適的諮詢空間，並提供您舒適且獨立的等候空間。',
  accompany: '專屬陪伴',
  accompanyContent: '由宜蘊團隊專人全程陪伴您看診做檢查、批價、領藥亦由宜蘊團隊為您代辦。',
  personReceptionContent_2: '宜蘊團隊將依您的療程結果，主動聯繫回報您的療程狀況，作為您專屬的健康管理師。',
  clinicRule: '看診須知',
  clinicRuleContent1: '宜蘊特約門診採約診制。',
  clinicRuleContent2: '宜蘊特約門診將透過諮詢了解您的需求並做適切的安排，因此預約的時段將以宜蘊特約門診告知為準，歡迎洽詢。',
  clinicRuleContent3: '宜蘊特約門診各項服務均屬於自費。',
  clinicRuleContent4: '宜蘊特約門診費用基本為每30分鐘新台幣2,000元，逾時依照此計費方式另行收費，請依個人需求預約合適時段(含掛號費、醫師基本診察與諮詢費用)，其他醫療相關費用及檢查費用另計。',
  specialClinicPhoneNumber: '特約門診約診電話',
  local: '國內',
  oversea: '國外',
  // ------RegistrationChoose------//
  schedule: '門診總表',
  doctor: '醫師',
  lastWeek: '上一週',
  nextWeek: '下一週',
  overTime: '已超時',
  book: '預約',
  return: '返回',
  confirmOutpatient: '確認門診',
  name: '姓名',
  namePlaceHolder: '請輸入您的姓名',
  date: '生日',
  uid: '身分證字號',
  uidInfo: '與護照/居留證號碼擇一填寫',
  passportNum: '護照/居留證號碼',
  passportNumInfo: '與身分證字號擇一填寫',
  contactNumber: '聯絡電話',
  contactPlaceholder: '請輸入聯絡電話號碼',
  emailPlaceholder: '請輸入email帳號',
  verification: '驗證碼',
  verificationPlaceholder: '請輸入驗證碼',
  sendVerificationByTextMsg: '簡訊發送驗證碼',
  sendVerificationByMail: '郵件發送驗證碼',
  verificationInfo: '輸入圖片顯示數字加總 以取得驗證碼',
  verificationAnswerPlaceholder: '請輸入答案',
  getVerification: '取得驗證碼',
  nextStep: '下一步',
  changePassword: '變更密碼',
  newPasswordInfo: '＊新密碼須為英數混合8碼以上，且含1大寫字母',
  newPassword: '新密碼',
  comfirmNewPassword: '確認新密碼',
  saveMod: '儲存修改',
  hello: '您好',
  overSeaServiceInfo: '非本國人客戶掛號，目前會由專人為您服務，歡迎您於以下時間來電',
  IvfClinic: '宜蘊生殖醫學中心門診',
  TcmClinic: '宜蘊中醫診所門診',
  passwordError: '密碼必須有 8 個字元，並包含大、小寫字母、數字，其中一個字母需為大寫',
  passwordComfirmError: '確認密碼與密碼不符',
  modifySuccess: '更改成功',
  noDoctor: '無法取得醫師資訊',
  selectDoctorInfo: '請先選擇掛號醫師',
  selectTimeInfo: '請先選擇預約時段',
  noDoctorSchedule: '無法取得醫師班表',
  customSWALSelectDoctor: '請選擇掛號醫師',
  customSWALSelectTime: '請選擇預約時段',
  customSWALFillName: '請填寫姓名',
  customSWALFillDate: '請填寫生日',
  customSWALFillPid: '請填寫身分證 或 居留證/護照號碼號碼',
  customSWALPidError: '身分證字號格式錯誤',
  customSWALSelectNumberLocal: '請選擇手機區碼',
  customSWALFillNumber: '請填寫手機號碼',
  customSWALFillMail: '請填寫信箱',
  customSWALClickVerification: '請點擊獲取驗證碼',
  customSWALFillVerification: '請輸入驗證碼',
  customSWALMailError: '請輸入Email',
  customSWALPhoneError: '請輸入手機號碼',
  sendingVerification: '驗證碼已送出',
  // ------RegistrationConfirm------//
  comfirmClinic: '請確認門診',
  doNotRefresh: '請勿重整頁面避免資料遺失',
  modifyOutpatient: '修改門診',
  refreshError: '由於頁面重整導致資料遺失，請重新掛號',
  lostColumns: '缺少必要欄位值, 請重新掛號',
  // ------RegistrationComplete------//
  registrationClinic: '預約門診',
  selectDoctorAndTime: '選擇醫師/時段',
  comfirmRegistration: '確認預約',
  registrationComplete: '掛號完成',
  registrationSuccess: '預約成功',
  registrationDate: '日期',
  registrationTime: '時段',
  registrationCategory: '診別',
  room: '診間',
  roomNum: '診',
  number: '號碼',
  yourName: '名字',
  passportOrEntryPassNum: '護照/居留證號',
  comingInfo: '就診時請攜帶健保IC卡與身分證明文件。',
  backToIndex: '回首頁',
  myregistration: '我的預約',
  ivf: '生殖醫學',
  tcm: '中醫',
  one: '一',
  two: '二',
  three: '三',
  four: '四',
  five: '五',
  sex: '六',
  seven: '七',
  eight: '八',
  dataError: '資料取得失敗',
  // ------memberSignup------//
  signup: '會員註冊',
  gender: '性別',
  female: '女性',
  male: '男性',
  accout: '帳號',
  pidOrPassportNum: '身分證字號/護照號碼',
  password: '密碼',
  passwordPlaceholder: '請輸入8-20位數之密碼',
  confirmPassword: '確認密碼',
  refillPasswordPlaceholder: '請再輸入一次密碼',
  getVerificationByTextMail: '獲取簡訊驗證碼',
  getVerificationByEMail: '獲取Email驗證碼',
  agree: '我同意',
  areadyHasAccout: '已經有會員了',
  customSWALInputName: '請輸入姓名',
  customSWALInputBirth: '請輸入生日',
  customSWALInputGender: '請選擇性別',
  customSWALInputAccount: '請輸入帳號',
  customSWALInputPassword: '請輸入密碼',
  customSWALComfirmPassword: '請確認密碼',
  customSWALInputNumber: '請輸入連絡電話',
  customSWALInputCorrectNumber: '請輸入正確的連絡電話',
  customSWALInputLocal: '請輸入區碼',
  customSWALAgreePrivacy: '請同意隱私權條款',
  // ------memberLogin------//
  memberLogin: '會員登入',
  loginError: '登入失敗, 帳密是否有誤?',
  loginAccountPlaceholder: '帳號為身份證字號',
  loginPasswordPlaceholder: '請輸入密碼',
  rememberMe: '記住我',
  fogotPassword: '忘記密碼?',
  // ------memberHome------//
  myInfo: '基本資料',
  pleaseLogin: '請先登入',
  // ------fogotPassword------//
  fogotPasswordTitle: '輸入個人資訊已重設密碼',
  fogotPasswordInfo: '請輸入您的個人資料，系統將寄送重設密碼信至您的電子郵件。',
  pleaseInputBirthDay: '請輸入生日',
  resetPasswordTextMail: '傳送重設密碼簡訊',
  resetPasswordEmail: '傳送重設密碼信件',
  hasAccount: '已經有帳戶？',
  mailSendingTitle: '已傳送重設密碼的電子郵件',
  mailSendingContent: '已將電子郵件傳送至您輸入的信箱，<br> 請依照電子郵件中指示重設密碼。',
  textMailSendingTitle: '已傳送重設密碼的簡訊',
  textMailSendingContent: '已將簡訊傳送至09xxxxxxxx，<br> 請依照簡訊中指示重設密碼。',
  memberNotFound: '查無此會員資料',
  complete: '完成',
  // ------memberData------//
  helloGuest: '貴賓，您好',
  // ------memberReserveList------//
  cancelReserve: '取消預約',
  pidAndPassportNum: '護照/居留證號',
  noRigistration: '尚未有掛號資料',
  comfirmCancel: '確認取消',
  cancelSucsess: '門診取消成功',
  // ------memberDataInfo------//
  patientNo: '病歷號碼',
  phoneNumber: '手機號碼',
  birthDay: '出生日期',
  memberDataPid: '身分證號',
  oldPassword: '原密碼',
  passwordLengthError: '密碼必須有8個字元',
  inputOldPassword: '請輸入舊密碼',
  // 團隊分類
  生殖醫療團隊: '生殖醫療團隊',
  麻醉醫師團隊: '麻醉醫師團隊',
  乳房外科團隊: '乳房外科團隊',
  護理諮詢團隊: '護理諮詢團隊',
  中醫醫療團隊: '中醫醫療團隊',
  藥師: '藥師',
  實驗室團隊: '實驗室團隊',
  // 我的預約
  roomOne: '一診',
  roomTwo: '二診',
  roomThree: '三診',
  roomFour: '四診',
  roomFive: '五診',
  roomSix: '六診',
  roomSeven: '七診',
  roomEight: '八診',
  // 後端回傳
  reserved401: '您本日已有預約掛號，請勿重複預約',
  reserved402: '本週已有預約掛號紀錄',
  verify400: '驗證失敗',
  verify401: '圖形驗證碼請勿空白',
  // 我的預約
  num: '號',
};
