<template>
  <div id="quickLinks">
    <div class="quickLinks-wrapper">
      <p class="supersmall title">{{$t('quicklink')}}</p>
      <div
        v-for="(item, k) in links"
        :key="`ql_${k}`"
        :class="`quickLinks-icon-${item.name}`"
        @click="snsLightbox(item.type, item.url)"></div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { isMobile } from 'mobile-device-detect';

const links = [
  {
    name: 'line',
    type: 0,
    url: 'https://line.me/R/ti/p/%40232ecljp',
  },
  {
    name: 'weChat',
    type: 1,
    url: 'https://wa.me/qr/HP4CITFXVXMFL1',
  },
  {
    name: 'whatsApp',
    type: 2,
    url: 'http://weixin.qq.com/r/zhMAGNnEZIQbrZ1790bc',
  },
];

export default {
  name: 'QuickLinks',
  data() {
    return {
      links,
      isMobile,
    };
  },
  methods: {
    ...mapMutations([
      'setSocialLightbox',
      'setBodylock',
    ]),
    snsLightbox(type, url) {
      if (this.isMobile) {
        window.open(url, '_blank');
      } else {
        this.setSocialLightbox({ status: true, type });
        this.setBodylock(true);
      }
    },
  },
};
</script>
