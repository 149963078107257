<template>
   <section id="baby"
   :style="{backgroundImage: `url(${require('@/assets/img/pattern.svg')})`}">
    <div class="w1400">
      <div class="baby-intro">
        <!-- <h3 class="eng">NUWA BABY</h3> -->
        <h5 class="txt-bold">{{$t('nuwaStories')}}<img class="deco_flower" src="@/assets/img/deco_flower.svg" alt=""></h5>
        <p class="small txt-dark_gray">
          {{$t('storiesConetnt')}}
        </p>
      </div>

      <div class="itembox_outer w1000">
        <swiper class="itembox" :options="swiperOption" v-if="showArticle.length">
          <swiper-slide
          v-for="(item, key) in showArticle"
          :key="key">
            <router-link :to="item.path" class="item">
              <div class="inner">
                <div class="item-cover">
                  <p class="txt-regular ls2 more">{{$t('readAritcal')}}</p>
                </div>
                <div class="img" :style="{backgroundImage: `url(${item.pic})`}"></div>
                <div class="item-container">
                  <p class="small">{{item.date}}</p>
                  <h6 class="txt-medium">{{item.title}}</h6>
                  <p class="context small txt-dark_gray" v-html="item.context"></p>
                </div>
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>

      <div class="btn-wrapper">
        <router-link to="story/1" class="btn">{{$t('more')}}</router-link>
      </div>
    </div>
  </section>
</template>

<script>
// import m01 from '@/assets/img/team/IVF_Center_team/1/1.png';
// import m02 from '@/assets/img/team/IVF_Center_team/1/2.png';
// import m05 from '@/assets/img/team/IVF_Center_team/1/5.png';
// import m20 from '@/assets/img/team/TCM_Center_team/1/1.png';

import { getStoryList } from '@/lib/public';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'IndexBaby',
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        navigation: {
          nextEl: '#baby .swiper-button-next',
          prevEl: '#baby .swiper-button-prev',
        },
      },
      showArticle: [],
    };
  },
  computed: {
    ...mapGetters([
      'getLangUUID',
    ]),
  },
  methods: {
    ...mapMutations([
      'setLangUUID',
    ]),
    getData() {
      this.showArticle = [];
      getStoryList(
        this.getLangUUID,
        (response) => {
          response.data.data.forEach((elem, index) => {
            if (index <= 4) {
              const temp = {
                pic: elem.doctor_image,
                path: `/storyarticle/${elem.slug}`,
                date: null,
                title: elem.title,
                context: elem.content,
              };
              this.showArticle.push(temp);
            }
          });
        },
      );
    },
  },
  watch: {
    getLangUUID(val) {
      if (val !== null) {
        this.getData();
      }
    },
  },
  mounted() {
    if (this.getLangUUID !== null) {
      this.getData();
    }
  },
};
</script>
<style>
  .context font {
    font: inherit;
  }
  .item-container img {
    display: none;
  }
</style>
