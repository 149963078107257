<template>
  <section id="news">
    <div class="w1200 news-container">
      <div class="col35 intro">
        <!-- <h3 class="eng">NUWA News</h3> -->
        <h5 class="txt-bold">
          {{$t('news')}}
          <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
        </h5>
      </div>
      <div class="col65">
        <ul class="news-list">
          <li v-for="(item, key) in showNews" :key="key">
            <router-link :to="item.link">
              <p class="date col10">{{item.date}}</p>
              <p class="type col15">{{item.type}}</p>
              <p class="context col70">{{item.context}}</p>
            </router-link>
          </li>
        </ul>
        <router-link to="/news/all/1" class="btn">{{$t('more')}}</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { getNewsTags, getNewsByNew } from '@/lib/public';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'IndexNews',
  data() {
    return {
      Classification: [],
      showNews: [],
    };
  },
  computed: {
    ...mapGetters([
      'getLangUUID',
    ]),
  },
  methods: {
    ...mapMutations([
      'setLangUUID',
    ]),
    getNews() {
      getNewsByNew(
        this.getLangUUID,
        (response) => {
          this.showNews = [];
          console.log(response);
          response.data.data.forEach((elem, index) => {
            console.log(index);
            if (index <= 4) {
              const temp = {
                link: `/newsarticle/${elem.slug}`,
                date: elem.publish_day.substring(0, 10),
                type: elem.tag_uuid,
                context: elem.title,
              };
              console.log(this.Classification);
              this.Classification.forEach((item) => {
                if (item.uuid === elem.tag_uuid) {
                  temp.type = item.name;
                }
              });
              this.showNews.push(temp);
            }
          });
        },
      );
    },
  },
  watch: {
    getLangUUID(val) {
      if (val !== null) {
        getNewsTags(
          this.getLangUUID,
          (response) => {
            response.data.data.forEach((elem) => {
              const temp = {
                uuid: elem.uuid,
                name: elem.name,
              };
              this.Classification.push(temp);
            });
          },
        ).then(() => {
          this.getNews();
        });
      }
    },
  },
  mounted() {
    if (this.getLangUUID !== null) {
      getNewsTags(
        this.getLangUUID,
        (response) => {
          console.log(response);
          response.data.data.forEach((elem) => {
            const temp = {
              uuid: elem.uuid,
              name: elem.name,
            };
            this.Classification.push(temp);
          });
        },
      ).then(() => {
        this.getNews();
      });
    }
  },
};
</script>
