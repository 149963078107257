import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*', // 當連到不是我們自訂義的路由時
    redirect: '/', // 重新指向路由回首頁
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue'),
  },
  {
    path: '/TechnicalSupport',
    name: 'TechnicalSupport',
    component: () => import('../views/TechnicalSupport.vue'),
  },
  {
    path: '/about/ivf',
    name: 'AboutIvf',
    component: () => import('../views/AboutIvf.vue'),
  },
  {
    path: '/about/lab',
    name: 'AboutLab',
    component: () => import('../views/AboutLab.vue'),
  },
  {
    path: '/about/tcm',
    name: 'AboutTcm',
    component: () => import('../views/AboutTcm.vue'),
  },
  {
    path: '/about/nuwa',
    name: 'AboutNuwa',
    component: () => import('../views/AboutNuwa.vue'),
  },
  {
    path: '/aboutinfo/:classification/:articleId',
    name: 'AboutInfo',
    props: true,
    component: () => import('../views/AboutInfo.vue'),
  },
  {
    path: '/staff/:tagSlug/:subject',
    name: 'Staff',
    props: true,
    component: () => import('../views/Staff.vue'),
  },
  {
    path: '/story/:page',
    name: 'Story',
    props: true,
    component: () => import('../views/Story.vue'),
  },
  {
    path: '/storyarticle/:articleId',
    name: 'StoryArticle',
    props: true,
    component: () => import('../views/StoryArticle.vue'),
  },
  {
    path: '/international',
    name: 'International',
    component: () => import('../views/International.vue'),
  },
  {
    path: '/news/:tagSlug/:currentPage',
    name: 'News',
    props: true,
    component: () => import('../views/News.vue'),
  },
  {
    path: '/newsarticle/:articleId',
    name: 'NewsArticle',
    props: true,
    component: () => import('../views/NewsArticle.vue'),
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('../views/Registration.vue'),
  },
  {
    path: '/registrationchoose/:classification',
    name: 'RegistrationChoose',
    props: true,
    component: () => import('../views/RegistrationChoose.vue'),
  },
  {
    path: '/registrationconfirm',
    name: 'RegistrationConfirm',
    component: () => import('../views/RegistrationConfirm.vue'),
  },
  {
    path: '/registrationcomplete',
    name: 'RegistrationComplete',
    component: () => import('../views/RegistrationComplete.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/MemberLogin.vue'),
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/MemberSignup.vue'),
  },
  {
    path: '/forgetpassword',
    name: 'ForgetPassword',
    component: () => import('../views/MemberForgetPassword.vue'),
  },
  {
    path: '/memberhome',
    name: 'MemberHome',
    component: () => import('../views/MemberHome.vue'),
  },
  {
    path: '/memberdata',
    name: 'MemberData',
    component: () => import('../views/MemberData.vue'),
  },
  // ===============================================
  // 後台預覽編輯
  {
    path: '/storyarticle/preview/:articleId',
    name: 'StoryArticlePreview',
    props: true,
    component: () => import('../views/StoryArticle.vue'),
  },
  {
    path: '/aboutinfo/preview/:classification/:articleId',
    name: 'AboutInfoPreview',
    props: true,
    component: () => import('../views/AboutInfo.vue'),
  },
  {
    path: '/newsarticle/preview/:articleId',
    name: 'newsPreview',
    props: true,
    component: () => import('../views/NewsArticle.vue'),
  },
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const el = document.getElementsByTagName('body')[0];
  el.classList.remove('lock');
  next();
});

export default router;
