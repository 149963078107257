function getLang(langArr, name, defaultValue) {
  const value = langArr.find((item) => item === name.toLocaleLowerCase());
  if (value) {
    return value;
  }
  return defaultValue;
}

export default {
  getLang,
};
